import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralTable from '@/components/GeneralTable';
import checkPermission from '@/utils/permission'; // 权限判断函数
import { PAGE_URL } from '@/utils/UrlUtils';
import { print, batchPrint, batchInvalidate } from '@/api/shop-authorization';
export default {
  name: 'Index',
  components: {
    GeneralTable: GeneralTable
  },
  data: function data() {
    return {
      tableName: 'v_client_audit_process_instance_zippo',
      moduleName: 'online_shop_list_module',
      url: {
        getUrl: '/shop/authorization',
        method: 'post',
        query: {
          param: {},
          sort: 'b_serial'
        }
      },
      operation: [],
      canSearch: checkPermission('master.shop.authorization.list'),
      //true,
      canAdd: false,
      //checkPermission('master.shop.add'), //true,
      canEdit: false,
      //checkPermission('master.shop.edit'), //true,
      canDelete: checkPermission('master.shop.authorization.print'),
      //true,
      canBatchEdit: false,
      //checkPermission('master.shop.batch'), //true,
      canDownload: checkPermission('master.shop.authorization.list'),
      //true
      canImport: checkPermission('master.shop.authorization.print'),
      canMultiCheck: true,
      isOnline: true,
      batchPrintLoading: false,
      hasPrintAuthorizationPrivilege: checkPermission('master.shop.authorization.print'),
      buttonBatchPrintLabel: "",
      batchInvalidDialogVisible: false,
      comment: ''
    };
  },
  created: function created() {
    this.buttonBatchPrintLabel = this.$t('button.batchPrint');
    var _this = this;
    this.operation = [{
      name: this.$t('button.print'),
      method: this.handleSinglePrint,
      type: 'primary',
      hide: function hide(item) {
        var flag = item.c_client_id && item.c_client_id == 21 && !item.b_first_print_date && item.b_audit_status == '通过' && _this.hasPrintAuthorizationPrivilege;
        if (flag) {
          return false;
        }
        return true;
      }
    }, {
      name: this.$t("button.againPrint"),
      method: this.handleSinglePrint,
      type: 'primary',
      hide: function hide(item) {
        var flag = item.c_client_id && item.c_client_id == 21 && item.b_first_print_date && item.b_audit_status == '通过' && _this.hasPrintAuthorizationPrivilege;
        if (flag) {
          return false;
        }
        return true;
      }
    }];
    if (this.$route.path.indexOf('online') < 0) {
      this.isOnline = false;
      this.url.query.param.b_shop_channel = [2];
      this.moduleName = "offline_shop_list_module";
    } else {
      this.isOnline = true;
      this.url.query.param.b_shop_channel = [1];
      this.moduleName = "online_shop_list_module";
    }
    this.url.query.param.b_audit_status = [2];
    this.url.query.param.b_has_print = [0];
  },
  mounted: function mounted() {
    var _this2 = this;
    if (this.$refs.GeneralTable) {
      this.$refs.GeneralTable.handleCollapse();
    }
    if (this.$refs.GeneralTable.currentTable) {
      var finalTableColumns = [];
      var onlineCodes = ["b_shop_channel", "b_authorize_index", "b_distributor_code", "b_distributor_id", "b_shop_distributor_code", "b_shop_distributor_id", "b_paper_no", "b_serial", "b_shop_name", "b_shop_old_code", "b_operation_mode", "b_business_type", "b_shop_contact", "b_shop_tel", "b_shop_email", "b_sales_user_id", "b_e_platform", "b_shop_address", "b_owner_name", "b_owner_idcard", "b_owner_contact", "b_authorize_start_date", "b_authorize_end_date", "b_has_print", "b_first_print_date", "b_is_valid", "b_invalid_time", "b_invalid_operator", "b_shop_comment", "c_rec_creator", "c_rec_create_time", "c_rec_revisor", "c_rec_revise_time"];
      //console.log(this.$refs.GeneralTable.listQuery.param)
      var offlineCodes = ["b_shop_channel", "b_authorize_index", "b_distributor_code", "b_distributor_id", "b_shop_distributor_code", "b_shop_distributor_id", "b_paper_no", "b_serial", "b_shop_name", "b_old_shop_code", "b_operation_mode", "b_shop_type", "b_business_type", "b_shop_contact", "b_shop_tel", "b_shop_email", "b_sales_user_id", "b_mall_name", "b_mall_floor", "b_mall_room_number", "b_shop_address", "b_owner_name", "b_owner_idcard", "b_owner_contact", "b_authorize_start_date", "b_authorize_end_date", "b_has_print", "b_first_print_date", "b_is_valid", "b_invalid_time", "b_invalid_operator", "b_shop_comment", "c_rec_creator", "c_rec_create_time", "c_rec_revisor", "c_rec_revise_time"];
      if (this.isOnline) {
        var _iterator = _createForOfIteratorHelper(onlineCodes),
          _step;
        try {
          var _loop = function _loop() {
            var code = _step.value;
            // console.log(code)
            var tempColumn = _this2.$refs.GeneralTable.currentTable.filter(function (t) {
              return code == t.code;
            });
            // console.log(tempColumn)
            if (tempColumn != null && tempColumn.length > 0) {
              finalTableColumns.push(tempColumn[0]);
            }
          };
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        var _iterator2 = _createForOfIteratorHelper(offlineCodes),
          _step2;
        try {
          var _loop2 = function _loop2() {
            var code = _step2.value;
            // console.log(code)
            var tempColumn = _this2.$refs.GeneralTable.currentTable.filter(function (t) {
              return code == t.code;
            });
            // console.log(tempColumn)
            if (tempColumn != null && tempColumn.length > 0) {
              finalTableColumns.push(tempColumn[0]);
            }
          };
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            _loop2();
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
      this.$refs.GeneralTable.currentTable = finalTableColumns; // this.$refs.GeneralTable.currentTable.filter(t=>!onlineExcludeCodes.includes(t.code))
      this.$refs.GeneralTable.setTableColumns();
      this.$refs.GeneralTable.getListData();
      // console.log(this.$refs.GeneralTable.tableColumns)
    }
  },
  methods: {
    handleImportPaperNo: function handleImportPaperNo() {
      this.$router.push('./import-data?module_name=import_paper_no_module');
    },
    handleAuthorizationRenewal: function handleAuthorizationRenewal() {
      this.$router.push('./import-data?module_name=authorization_renewal_module');
    },
    handleSinglePrint: function handleSinglePrint(index, item) {
      this.handlePrint('' + item.c_id);
    },
    handleBatchPrint: function handleBatchPrint() {
      var _this3 = this;
      var _this = this;
      var selectedData = _this.$refs.GeneralTable.selectedData;
      if (selectedData && selectedData.length > 0) {
        var flag = true;
        selectedData.forEach(function (item, index) {
          if (item.b_audit_status != '通过') {
            flag = false;
            _this3.$message({
              message: item.b_shop_name + '授权未通过，不可打印',
              type: 'error'
            });
            return;
          }
        });
        if (!flag) {
          return;
        }
        var ids = '';
        selectedData.forEach(function (item, index) {
          ids += item.c_id + ',';
        });
        _this.handlePrint(ids);
      } else {
        var total = this.$refs.GeneralTable.total;
        // console.log('handleBatchPrint 2：' + total)
        if (this.$refs.GeneralTable.total == 0) {
          this.$message({
            message: this.$t('shopAuthorization.noPrintRecord'),
            type: 'warning'
          });
          return;
        }
        this.$confirm(total + this.$t('shopAuthorization.authorizationPrintConfirm'), this.$t('messageBox.confirm.title'), {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'warning'
        }).then(function () {
          _this3.showPrintLoading(true);
          var __this = _this3;
          var i = 0;
          var timer = null;
          timer = setInterval(function () {
            console.log('setInterval:' + ++i);
            if (i >= total || i >= 30) {
              __this.showPrintLoading(false);
              clearInterval(timer);
            }
          }, 1000);
          batchPrint(_this.$refs.GeneralTable.listQuery).then(function (res) {
            if (!res.data.success) {
              _this3.$message({
                message: res.data.message,
                type: 'error'
              });
              return;
            }
            if (res.data.data.length == 0) {
              _this3.$message({
                message: _this3.$t('shopAuthorization.noPrintRecord'),
                type: 'warning'
              });
              return;
            }
            sessionStorage.setItem('printInfoList', JSON.stringify(res.data.data));
            window.open(PAGE_URL + 'print.html');
          });
        });
      }
    },
    handlePrint: function handlePrint(ids) {
      var _this4 = this;
      // console.log(ids)
      if (ids.lastIndexOf(',') == ids.length - 1) {
        ids = ids.substring(0, ids.length - 1);
      }
      var total = ids.split(',').length;
      this.$confirm(total + this.$t('shopAuthorization.authorizationPrintConfirm'), this.$t('messageBox.confirm.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(function () {
        _this4.showPrintLoading(true);
        var __this = _this4;
        var i = 0;
        var timer = null;
        timer = setInterval(function () {
          console.log('setInterval:' + ++i);
          if (i >= total || i >= 30) {
            __this.showPrintLoading(false);
            clearInterval(timer);
          }
        }, 1000);
        print(ids).then(function (res) {
          if (!res.data.success) {
            _this4.$message({
              message: res.data.message,
              type: 'error'
            });
            clearInterval(timer);
            return;
          }
          __this.$refs.GeneralTable.$refs.multipleTable.clearSelection();
          sessionStorage.setItem('printInfoList', JSON.stringify(res.data.data));
          window.open(PAGE_URL + 'print.html');
        });
      });
    },
    openBatchInvalidDialog: function openBatchInvalidDialog() {
      // console.log('openBatchInvalidDialog')
      var selectedData = this.$refs.GeneralTable.selectedData;
      if (selectedData == null || selectedData.length == 0) {
        this.$message({
          message: this.$t('shopAuthorization.noInvalidateRecord'),
          type: 'warning'
        });
      } else {
        this.batchInvalidDialogVisible = true;
      }
    },
    handleBatchInvalid: function handleBatchInvalid() {
      var _this5 = this;
      if (isNull(this.comment)) {
        this.$message({
          message: this.$t('shopAuthorization.inputComment'),
          type: 'warning'
        });
        return;
      }
      var _this = this;
      var selectedData = _this.$refs.GeneralTable.selectedData;
      var ids = '';
      if (selectedData && selectedData.length > 0) {
        var _iterator3 = _createForOfIteratorHelper(selectedData),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var item = _step3.value;
            ids = ids + ',' + item['c_id'];
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
        if (ids.length > 0 && ids.substring(0, 1) === ',') {
          ids = ids.substring(1, ids.length);
        }
        // console.log(ids)
        this.$confirm(this.$t('messageBox.confirm.body'), this.$t('messageBox.confirm.title'), {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'warning'
        }).then(function () {
          _this5.batchInvalidDialogVisible = false;
          batchInvalidate(ids, _this.comment).then(function (response) {
            //console.log(response)
            if (response.data.code === '0') {
              _this5.$notify({
                title: _this5.$t('notification.success.title'),
                message: _this5.$t('notification.success.body'),
                type: 'success',
                duration: 3000
              });
            } else {
              _this5.$notify({
                title: _this5.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 3000
              });
            }
            _this5.$refs.GeneralTable.getListData();
            resolve(response);
          }).catch(function (error) {
            // reject(error)
          });
        });
      }
    },
    showPrintLoading: function showPrintLoading(loading) {
      this.batchPrintLoading = loading;
      if (loading) {
        this.buttonBatchPrintLabel = this.$t("shopAuthorization.buttonBatchPrintLoading");
      } else {
        this.buttonBatchPrintLabel = this.$t("button.batchPrint");
      }
    },
    beforeRouteLeave: function beforeRouteLeave(to, from, next) {
      // 保存离开本页面时的查询条件，当再次进入本页面时回显
      if (this.$refs.GeneralTable) {
        sessionStorage.setItem(window.location.href + '_LIST_QUERY', JSON.stringify(this.$refs.GeneralTable.getListQuery()));
      }
      next();
    }
  }
};