var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("GeneralTable", {
        ref: "GeneralTable",
        attrs: {
          "module-name": _vm.moduleName,
          "table-name": _vm.tableName,
          "row-key": "c_id",
          url: _vm.url,
          operation: _vm.operation,
          "can-search": _vm.canSearch,
          "can-import": _vm.canImport,
          "can-add": _vm.canAdd,
          "can-edit": _vm.canEdit,
          "can-delete": _vm.canDelete,
          "can-download": _vm.canDownload,
          "can-batch-edit": _vm.canBatchEdit,
          "can-multi-check": _vm.canMultiCheck,
          "cascader-check-strictly": false,
        },
        scopedSlots: _vm._u([
          {
            key: "moreQueryButton",
            fn: function (slotProps) {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary" },
                    on: { click: _vm.openBatchInvalidDialog },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.batchInvalid")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.hasPrintAuthorizationPrivilege
                  ? _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: {
                          type: "primary",
                          loading: _vm.batchPrintLoading,
                        },
                        on: { click: _vm.handleBatchPrint },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.buttonBatchPrintLabel) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleImportPaperNo },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.importPaperNo")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleAuthorizationRenewal },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.authorizationRenewal")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            visible: _vm.batchInvalidDialogVisible,
            title: _vm.$t("button.batchInvalid"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchInvalidDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: _vm.$t("shopAuthorization.inputComment"),
                },
                model: {
                  value: _vm.comment,
                  callback: function ($$v) {
                    _vm.comment = $$v
                  },
                  expression: "comment",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.batchInvalidDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleBatchInvalid },
                },
                [_vm._v(_vm._s(_vm.$t("button.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }