import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
/**
 * 判断地址栏URL类型
 * return 0=本地 1=ip 2=顶级域名 3=二级或以上域名
 */
export function typeOfUrl(url) {
  var test = url.replace("https://", "").replace("http://", "").split("/")[0].split(":")[0];
  if (test.indexOf('localhost') == 0 || test.indexOf('127.0.0.1') == 0) {
    return 0;
  }
  var reg = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  if (reg.test(test)) {
    return 1;
  } else {
    var firstDomain = test.split('.')[0];
    if (firstDomain === 'www' || firstDomain === 'crm') {
      return 2;
    } else {
      return 3;
    }
  }
}

/**
 * 从浏览器地址栏获取协议，http或https
 */
export function getProtocol(url) {
  var protocol = '';
  if (url.indexOf('https') === 0) {
    protocol = 'https';
  } else {
    protocol = 'http';
  }
  console.log('parse url:protocol=' + protocol);
  return protocol;
}

/**
 * 从浏览器地址栏获取域名/ip
 */
export function getHost(url) {
  var host = url.replace("https://", "").replace("http://", "").split('/')[0].split(':')[0];
  console.log('parse url:host=' + host);
  return host;
}

/**
 * 从浏览器地址栏获取端口
 */
export function getPort(url) {
  var port = '';
  var a = url.replace("https://", "").replace("http://", "").split('/')[0].split(':');
  if (a.length == 2) {
    port = ':' + a[1];
  }
  console.log('parse url:port=' + port);
  return port;
}
var PROTOCOL_HEADER = getProtocol(window.location.href);
// Socket 协议头
var SOCKET_HEADER = PROTOCOL_HEADER === 'http' ? 'ws' : 'wss';
// 【部署时可能需要修改处1/2】服务器域名/ip：指定值或从浏览器地址栏获取
var SERVER_DOMAIN = getHost(window.location.href);
// 【部署时可能需要修改处2/2】服务器端口：指定值或从浏览器地址栏获取
// 强烈建议除本地开发环境外，部署环境一律通过Nginx配置反向代理使前后台端口一致
//const SERVER_PORT = ':8888'
var SERVER_PORT = getPort(window.location.href);
var PAGE_PORT = getPort(window.location.href);
// 项目环境路径
var CONTEXT_PATH = 'api';
// 网页地址，不包括api后缀
var PAGE_URL = "".concat(PROTOCOL_HEADER, "://").concat(SERVER_DOMAIN).concat(PAGE_PORT, "/");
export { PAGE_URL, PROTOCOL_HEADER };