import request from '@/utils/request';
export function print(ids) {
  return request({
    url: '/shop/authorization/print',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
export function batchPrint(params) {
  return request({
    url: '/shop/authorization/batch-print',
    method: 'post',
    data: params
  });
}
export function batchCancel(ids) {
  return request({
    url: '/shop/authorization/batch-cancel',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
export function batchInvalidate(ids, comment) {
  return request({
    url: '/shop/authorization/batch-invalidate',
    method: 'post',
    params: {
      ids: ids,
      comment: comment
    }
  });
}